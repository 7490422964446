export default async function({ app, redirect, $axios, $config }) {
    if (!$config.ENVIRONMENT.includes('production')) {
        const cookieName = app.$config.COOKIE_NAME
        const cookie = app.$cookies.get(cookieName)

        try {
            if (!cookie || cookie === undefined) {
                return redirect('/login')
            } else {
                $axios.setHeader('SportBLXAuthorization', cookie.id)
                const newCookie = await $axios.$get('/refresh')

                if (
                    !newCookie ||
                    !newCookie.id ||
                    !newCookie.expiry
                ) {
                    return redirect('/login')
                }

                app.$cookies.set(cookieName, newCookie, {
                    path: '/',
                    expires: new Date(newCookie.expiry)
                })
            }
        } catch (err) {
            return redirect('/login')
        }
    }
}
