/**
 * All CMS services for ANNESTESFARM
 */
export class CmsService {
  // KEYS
  static KEY_ANNESTESFARM_MAX_PLAYER = 'ANNESTESFARM_MAX_PLAYER';
  static KEY_COMPANY_LOGO = 'ANNESTESFARM_LOGO';
  static KEY_SECTION_FOOTER = 'ANNESTESFARM_FOOTER';

  // Apply the app settings to the state
  static applyAppSettings(appSettings, state) {
    if (!appSettings) {
      return null
    }

    appSettings.forEach((setting) => {
      switch (setting.key) {
        // PAGE
        case (CmsService.KEY_ANNESTESFARM_MAX_PLAYER):
          state.maxPlayerSetting = setting
          break
        // SECTION
        case (CmsService.KEY_SECTION_FOOTER):
          state.sectionFooter = setting
          break
        // TEXT
        case (CmsService.KEY_COMPANY_LOGO):
          state.textCompanyLogo = setting.value
          state.textFooterLogo = setting.description || setting.value
          break
        default:
          return null
      }
    })
  }
}
